<template>
	<main>
		<header>
			<img src="/static/img/placebo.png" alt="placebo" />
			<img src="/static/img/glbc.png" alt="glbc" />
		</header>
		<section class="main">
			<div class="data">18.06.21 <span>22:00</span></div>
			<div class="circle-present">
				18.06.21
			</div>
			<h1>
				BORIS
				<br />
				brejcha
				<p>Ann Clue</p>
			</h1>
			<h2>Support by sestrica × secret guest</h2>
			<p class="artist">
				Главный представитель минимал-техно, как многие его называют.
				Артист, который шел к этому признанию 15 лет
				<br class="non-mobile"/>
				и чья популярность растет <br class="mobile"> с каждым годом — Boris Brejcha
				возвращается  <br class="mobile"> в Санкт-Петербург!
			</p>
			<button
				class="buy-tickets"
				data-tc-event="6033fa422503fbb6cbe06b1e"
				data-tc-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNjAzMzY5YzQxMzkzZWUyMjc3ZTBmMmQzIn0.M_Ro4zwMpjS7WVdYmnAMPrgo4y7yrm8sxuMCEpG2hoo"
			>
				Купить билет от 4500 ₽
			</button>
			<!-- <a href="https://t.me/p25entmt">Vip-резерв: <img src="/static/img/telegram.svg" alt=""> @p25entmt</a> -->
		</section>
		<section class="bottom">
			<img class="icon" src="/static/img/icon.png" alt="" />

			<p>
				Temple of Deer — более 15 000 m² пространств исторически ценных
				строений, которые прямо сейчас превращаются силами группы
				Placebo/25 в мультимедийную концертную площадку нового поколения
				на 4500 гостей, андеграундный ночной клуб, комплекс фотостудий,
				азиатский велнес-спа, крупный фуд-маркет с аутентичными мировыми
				концепциями, рестораны, мебельный концепт-стор, ритейл авеню
				фешн-брендов, коворкинг и флагманские <br class="no-mobile"> ивент-пространства LOFT
				HALL.
				<br />
				<br />
				Концертный комплекс внутри Temple of Deer это: 8 авторских
				баров, более 50 киловатт <br class="mobile"> и 55 источников звука <br class="no-mobile"> от французского
				бренда Hi-End оборудования L-acoustic, более 100 m² светодиодных
				экранов нового поколения, премиальный комплект бэклайна <br class="mobile"> и
				гримерные комнаты артистов, оборудованные по стандарту <br />
				5-звездочного отеля.
			</p>
			<form action="/static/templeofdeer_2021.pdf" @submit="handlePdf">
				<button type="submit" class="buy-tickets">
					Подробнее о площадке
				</button>
			</form>

			<div class="socila-icons">
				<a href="https://www.facebook.com/p25entmt/"
					><img src="/static/img/facebook.svg" alt="facebook"
				/></a>
				<a href="https://www.instagram.com/p25entmt/"
					><img src="/static/img/instagram.svg" alt="insta"
				/></a>
				<a href="https://vk.com/p25entmt/"
					><img src="/static/img/vk.svg" alt="vk"
				/></a>
			</div>
			<div class="sub-info">
				<a href="https://goo.gl/maps/PnQgvvZR5PoHHWGJ7">
					Санкт-Петербург, Арсенальная набережная, 1
				</a>
				<br />
				<br>
				<a href="mailto:entertainment@placebo25.com">
					entertainment@placebo25.com
				</a>
			</div>
			<div class="copyright">
				Placebo/25 © 2021
			</div>
		</section>
	</main>
</template>
<script>

export default {
    methods: {
        handlePdf(e) {
            e.preventDefault();
            ym(72825025, 'reachGoal', 'about');
            gtag('event', 'about', {
                event_category: 'download',
            });
            document.location = "/static/templeofdeer_2021.pdf";
        }
    }
}
</script>

<style lang="scss">
@import "./fonts.scss";
$size-base: 16px;

@function ptr($size) {
	@return #{$size / $size-base}rem;
}

$btn-color: linear-gradient(180deg, #82a1eb 0%, #9d9ded 100%);
$sub-btn-color: rgba(82, 95, 218, 0.67);
$text-color: white;
$h-text: #fe7511;

$main-font: "SF Pro Display", sans-serif;
$h-font: "MADE Outer Sans", sans-serif;

html {
	font-size: 0.8333333333vw;
}

@media (max-width: 900px) {
	html {
		font-size: 5vw;
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	font-family: $main-font;
	&:focus {
		outline: none;
	}
}

body {
	width: 100%;
	background-image: url("/static/img/back.jpg");
	background-color: #101115;
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
}

section,
header {
	position: relative;
	z-index: 2;
}

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ptr(30px) ptr(43px) 0 ptr(60px);
	img {
		width: ptr(204px);
		&:nth-child(2) {
			width: ptr(210px);
		}
	}
}

.main {
	margin-top: ptr(65px);
	display: grid;
	gap: ptr(15px);
	justify-content: center;
	text-align: center;
	color: white;
	a{
		margin-top: ptr(20px);
		display: inline-flex;
		align-items: center;
		justify-self: center;
		color: white;
		font-size: ptr(16px);
		text-decoration: none;
		img{
			width: ptr(30px);
			margin: 0 ptr(10px);
		}
	}
}
.data {
	font-family: $h-font;
	font-size: ptr(25px);
	font-weight: 500;
	span {
		font-family: $h-font;
		margin-left: ptr(30px);
	}
}
.circle-present {
	position: absolute;
	border-radius: 50%;
	background: #b61630;
	right: ptr(670px);
	width: ptr(145px);
	top: ptr(-20px);
	height: ptr(145px);
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	transform: rotate(14deg);
	font-size: ptr(20px);
	line-height: ptr(20px);
}
h1 {
	font-weight: bold;
	font-size: ptr(87px);
	line-height: ptr(87px);
	font-family: $h-font;
	z-index: 3;
	color: $h-text;
	text-transform: uppercase;
	background-color: #ffc499;
	color: rgba(255, 255, 255, 0);
	text-shadow: 0 0 ptr(4px) $h-text;
	background-clip: text;
	p {
		line-height: ptr(63px);
		font-family: $h-font;
		font-weight: bold;
		font-size: ptr(63px);
		margin-top: ptr(10px);
	}
}
h2 {
	text-transform: uppercase;
	font-family: $h-font;
	font-weight: 500;
	letter-spacing: ptr(4px);
	margin: ptr(15px) 0 ptr(40px);
}
.artist {
	font-size: ptr(20px);
}
.buy-tickets {
	border-radius: 100px !important;
	background: white !important;
	width: ptr(302px);
	height: ptr(57px);
	margin-top: ptr(30px);
	border: none;
	font-size: ptr(16px) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	justify-self: center !important;
	color: black !important;
	text-shadow: unset !important;
	text-transform: none !important;
	font-family: $main-font !important;
	font-weight: 500 !important;
	transition: 0.2s;
}
form {
	display: flex;
	margin-top: ptr(30px);
	height: ptr(57px);
	justify-content: center;
	.buy-tickets {
		margin-top: 0;
	}
}
.bottom {
	margin-top: ptr(258px);
	display: grid;
	gap: ptr(50px);
	padding-bottom: ptr(70px);
	.icon {
		width: ptr(77px);
		justify-self: center;
	}
	p {
		margin-top: ptr(5px);
		color: white;
		width: ptr(872px);
		text-align: center;
		justify-self: center;
		font-size: ptr(16px);
		line-height: ptr(33px);
	}
}
.socila-icons {
	justify-self: center;
	display: flex;
	align-items: center;
	margin-top: ptr(20px);
	img {
		width: ptr(11px);
	}
	a:nth-child(2) {
		margin: 0 ptr(21px) 0 ptr(26px);
		img {
			width: ptr(16px);
		}
	}
	a:nth-child(3) img {
		width: ptr(22px);
	}
}
.sub-info {
	margin-top: ptr(10px);
	font-size: ptr(20px);
	text-align: center;
	a {
		text-decoration: none;
		color: $h-text;
	}
}
.copyright {
	color: rgb(190, 190, 190);
	font-size: ptr(16px);
	text-align: center;
}

.mobile{
	display: none;
}

@media (max-width: 900px) {
	header {
		display: none;
	}
	.bottom {
		margin-top: ptr(150px);
		p {
			width: ptr(320px);
			font-size: ptr(14px);
			line-height: ptr(23px);
			padding: 0 ptr(15px);
		}
	}
	body {
		width: 100vw;
		&::before {
			top: 0;
			width: 100%;
			height: 100vh;
			background-size: cover;
		}
	}
	.data {
		font-size: ptr(15px);
		span {
			margin-left: ptr(10px);
		}
		margin-bottom: ptr(20px);
	}
	h1 {
		font-size: ptr(40px);
		line-height: ptr(45px);
		p {
			line-height: ptr(28px);
			font-size: ptr(28px);
		}
	}
	.circle-present {
		display: none;
	}
	h2 {
		font-size: ptr(13px);
	}
	.artist {
		font-size: ptr(12px);
		padding: 0 ptr(15px);
	}
	.buy-tickets {
		margin-top: ptr(40px);
		font-size: ptr(14px);
		width: ptr(280px);
		height: ptr(60px);
	}
	.sub-info {
		font-size: ptr(14px);
	}
	.socila-icons{
		margin-top: ptr(30px);
		a:nth-child(2) {
			margin: 0 ptr(42px) 0 ptr(52px);
		}
	}
	.non-mobile{
		display: none;
	}
	.copyright {
		font-size: ptr(12px);
	}
	.mobile{
		display: block;
	}

}
</style>